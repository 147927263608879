/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './CustomFileUpload.module.scss';

function CustomFileUpload({
  isMultiple,
  errors = {},
  onFilesChange,
  trigger,
  initialFiles = [],
  title,
  disabled,
}) {
  const [files, setFiles] = useState(initialFiles);
  const pdfImg =
    'https://thesoftwarepro.com/wp-content/uploads/2019/12/microsoft-office-pdf-document-953x1024.jpg';
  const videoImg =
    'https://image.shutterstock.com/image-vector/play-button-icon-vector-illustration-260nw-1697833306.jpg';
  const audioImg = 'https://cdn.pixabay.com/photo/2017/11/10/05/34/sound-2935466_1280.png';

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles([...files, ...selectedFiles]);
    onFilesChange([...files, ...selectedFiles]);
    trigger('fileInput');
  };

  const handleCancel = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
    onFilesChange(updatedFiles);
    trigger('fileInput');
  };

  const renderFilePreview = (file, index) => {
    let imageSrc;
    if (typeof file === 'string') {
      imageSrc = file;
    } else if (file.type.startsWith('image/')) {
      imageSrc = URL.createObjectURL(file);
    } else if (file.type.startsWith('video/')) {
      imageSrc = videoImg;
    } else if (file.type.startsWith('audio/')) {
      imageSrc = audioImg;
    } else {
      imageSrc = pdfImg;
    }
    return (
      <div key={index} className={styles.uploaded_imgsection}>
        <img
          id={styles.uploaded_img}
          src={imageSrc}
          alt="preview"
          className="img-thumbnail img-fluid uploaded-img mr-2"
        />
      </div>
    );
  };

  return (
    <div>
      <div className="d-flex">
        <div className="d-flex">
          <div className="file-uploader-mask d-flex justify-content-center align-items-center" />
          <input
            id="upload"
            className="file-input"
            type="file"
            multiple={isMultiple}
            onChange={handleFileChange}
            disabled={disabled}
            hidden
          />
          <label htmlFor="upload" className={styles.upload_btn}>
            {title}
          </label>
        </div>
      </div>
      {errors.message && (
        <p
          style={{
            color: 'red',
            marginTop: '5px',
            fontSize: '14px',
          }}
        >
          {errors.message}
        </p>
      )}
      <div className="image upload mt-2">
        {files.map((file, index) => (
          <div key={file?.name}>
            {renderFilePreview(file, index)}
            <button
              type="button"
              onClick={() => handleCancel(index)}
              className={styles.imgcancel_btn}
              disabled={disabled}
            >
              Remove
            </button>
            {errors && errors.length > 0 && (
              <div>
                <p
                  key={new Date().toISOString()}
                  style={{
                    color: 'red',
                    marginTop: '5px',
                    fontSize: '14px',
                  }}
                >
                  {errors[index]?.message}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

CustomFileUpload.propTypes = {
  isMultiple: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  trigger: PropTypes.func.isRequired,
  onFilesChange: PropTypes.func.isRequired,
  initialFiles: PropTypes.array,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

CustomFileUpload.defaultProps = {
  initialFiles: [],
  title: 'Choose File',
  disabled: false,
};

export default CustomFileUpload;
